import { FaTag } from "react-icons/fa/";
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import { ThemeContext } from "../layouts";
import Hero from "../components/Hero";
import Blog from "../components/Blog";
import Footer from "../components/Footer";
const config = require("../../content/meta/config");

const NotFoundPage = props => {
  const {
    data: {
      site: {
        siteMetadata: { facebook, cover }
      },
      bgDesktop: {
        resize: { src: desktop }
      },
      bgTablet: {
        resize: { src: tablet }
      },
      bgMobile: {
        resize: { src: mobile }
      },
    }
  } = props;


  const backgrounds = {
    desktop,
    tablet,
    mobile
  };

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Hero
            disableScroll={true}
            backgrounds={backgrounds}
            theme={theme}
            title="Error: 404"
            subtitle="The page you were looking for cannot be found, it may have been moved or no longer exists."
          />
        )}
      </ThemeContext.Consumer>


      <ThemeContext.Consumer>
        {theme => <Footer config={config} theme={theme} />}
      </ThemeContext.Consumer>

      <Seo facebook={facebook} />
    </React.Fragment>
  );
};


export default NotFoundPage;

// eslint-disable-next-line no-undef
export const notFoundQuery = graphql`
  query PostsByTags {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
}
`;
